import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogClosedSubject = new Subject<any>();
  dialogClosed$ = this.dialogClosedSubject.asObservable();

  constructor(public dialog: MatDialog) { }

  openDialog(component: any, data?: any): Observable<any> {
    const dialogRef = this.dialog.open(component, {
      width: 'auto',
      data: data
    });

    return dialogRef.afterClosed();
  }

  closeAllDialogs(): void {
    this.dialog.closeAll()
  }

  closeDialog(dialogRef: any, data: any): void {
    dialogRef.close(data);
  }

  openCustom(component: ComponentType<any>, config?: MatDialogConfig<any>): Observable<any> {
    return this.dialog.open(component, config).afterClosed();
  }

  notifyDialogClosed(data: any) {
    this.dialogClosedSubject.next(data);
  }

}
